import cn from 'classnames';
import './Header.scss';
import { Logo } from '../logo/Logo';

import logout from '../../assets/img/logout.png';
import { useIsAuth } from '../../store/user/selectors';
import { userActions } from '../../store/user/userSlice';
import { useAppDispatch } from '../../store/useAppDispatch';

interface IHeaderProps {
  className?: string;
}

export const Header = (props: IHeaderProps) => {
  const { className } = props;

  const dispatch = useAppDispatch();

  const isAuth = useIsAuth();

  const handleLogout = () => {
    dispatch(userActions.logout());
    window.location.reload();
  };

  return (
    <header className={cn('header', className)}>
      <div className='container'>
        <div className='header_wrapper'>
          <Logo className='header_logo' />
          {isAuth && (
            <img
              onClick={handleLogout}
              className='header_logount-icon'
              src={logout}
              alt='Выйти'
            />
          )}
        </div>
      </div>
    </header>
  );
};
