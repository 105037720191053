import cn from 'classnames';
import { Text } from '../text/Text';
import './Select.scss';

interface ISelectProps<TValue = string> {
  className?: string;
  caption?: string;
  name: string;
  value: TValue;
  list: { name: string; value: TValue }[];
  onChange?: (val: TValue) => void;
}

export function Select<T extends string>(props: ISelectProps<T>) {
  const { list, caption, className, name, value, onChange } = props;

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as T;
    onChange?.(value);
  };

  return (
    <div className={cn('select', className)}>
      <Text
        className='select_caption'
        uppercase
      >
        {caption}
      </Text>

      <select
        name={name}
        value={value}
        onChange={handleChange}
      >
        {list.map(({ name, value }) => (
          <option
            key={value}
            value={value}
          >
            {name}
          </option>
        ))}
      </select>
    </div>
  );
}
