import { Page } from '../../../../layouts/page/Page';
import { MainPageByRole } from '../ui/MainPageByRole';
import {
  AppRoutes,
  getRouteHistory,
  getRouteLogin,
  getRouteMain,
  getRouteProfile,
  getRouteRecover,
  getRouteRegister
} from '../../../../shared/constants/router';
import { AppRoutesProps } from '../../../../shared/types/router';
import { UserRole } from '../../../../store/user/roles';
import { ProfilePage } from '../../../../pages/profile/ProfilePage';
import { HistoryPage } from '../../../../pages/history/HistoryPage';
import { AuthPage } from '../../../../pages/auth/AuthPage';
import { RegisterPage } from '../../../../pages/register/RegisterPage';
import { RecoverPage } from '../../../../pages/recover/RecoverPage';

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.MAIN]: {
    path: getRouteMain(),
    element: <MainPageByRole />,
    authOnly: true
  },
  [AppRoutes.PROFILE]: {
    path: getRouteProfile(),
    element: <ProfilePage />,
    authOnly: true
  },
  [AppRoutes.HISTORY]: {
    path: getRouteHistory(),
    element: <HistoryPage />,
    authOnly: true
  },
  [AppRoutes.LOGIN]: {
    path: getRouteLogin(),
    element: <AuthPage />,
    exceptAuth: true
  },
  [AppRoutes.REGISTER]: {
    path: getRouteRegister(),
    element: <RegisterPage />,
    authOnly: true,
    roles: [UserRole.admin]
  },
  [AppRoutes.RECOVER]: {
    path: getRouteRecover(),
    element: <RecoverPage />,
    authOnly: true,
    roles: [UserRole.admin]
  },

  // LAST
  [AppRoutes.NOT_FOUND]: {
    path: '*',
    element: <Page>Нет такой страницы</Page>
    // element: <NotFoundPage />
  }
};
