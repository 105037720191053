import cn from 'classnames';
import './Page.scss';
import { Header } from '../../components/header/Header';
import { Footer } from '../../components/footer/Footer';
import { ReactNode } from 'react';

interface IPageProps {
  className?: string;
  children: ReactNode;
}

export const Page = (props: IPageProps) => {
  const { className, children } = props;

  return (
    <div className={cn('page', className)}>
      <Header className='page_header' />
      <div className='container'>
        <div className='page_content'>{children}</div>
      </div>
      <Footer className='page_footer' />
    </div>
  );
};
