import cn from 'classnames';
import { Text } from '../text/Text';

import './Footer.scss';

import carIcon from '../../assets/img/car.png';
import userIcon from '../../assets/img/user.png';
import historyIcon from '../../assets/img/history.png';
import { Button } from '../button/Button';
import { Link } from 'react-router-dom';
import { useIsAuth, useRole } from '../../store/user/selectors';
import { UserRole } from '../../store/user/roles';
import { getRouteHistory, getRouteMain, getRouteProfile } from '../../shared/constants/router';

interface IFooterProps {
  className?: string;
}

export const Footer = (props: IFooterProps) => {
  const { className } = props;

  const isAuth = useIsAuth();
  const userRole = useRole();

  const noAuthFooter = (
    <div className='footer_wrapper'>
      <Text
        className='footer_text'
        align='center'
        size={12}
        underline
      >
        Сервис является внутренней системой компании АКИ-ТАКСИ
      </Text>
      <Text
        align='center'
        size={20}
        uppercase
      >
        8 800 800 80 80
      </Text>
    </div>
  );

  const authFooter = (
    <div className='footer_wrapper footer_wrapper__auth'>
      <Link to={getRouteMain()}>
        <Button icon={carIcon}>На главную</Button>
      </Link>
      <Link to={getRouteProfile()}>
        <Button icon={userIcon}>Личные данные</Button>
      </Link>
      {userRole === UserRole.driver && (
        <Link to={getRouteHistory()}>
          <Button icon={historyIcon}>История</Button>
        </Link>
      )}
    </div>
  );

  return (
    <footer className={cn('footer', className)}>
      <div className='container'>{isAuth ? authFooter : noAuthFooter}</div>
    </footer>
  );
};
