import { buildSelector } from '../buildSelector';

export const [useAuthData] = buildSelector((state) => state.user.authData);
export const [useIsAuth] = buildSelector((state) => !!state.user.authData);
export const [useIsInited] = buildSelector((state) => !!state.user._inited);

export const [useRole] = buildSelector((state) => state.user.authData?.role);

export const [useUserError] = buildSelector((state) => state.user.error);
export const [useUserLoading] = buildSelector((state) => state.user.isLoading);
