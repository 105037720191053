import cn from 'classnames';
import './Input.scss';
import { Text } from '../text/Text';
import { MouseEventHandler } from 'react';

interface IInputProps {
  className?: string;
  caption?: string;
  type?: React.HTMLInputTypeAttribute;
  disabled?: boolean;
  maxWidthInputItem?: number;
  placeholder?: string;
  value: string | number;
  onChange?: (val: string) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
  validator?: (val: string) => boolean;
}

export const Input = (props: IInputProps) => {
  const { className, caption, type = 'text', disabled, maxWidthInputItem, placeholder, value, onChange, onClick } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    onChange?.(value);
  };

  return (
    <div
      onClick={onClick}
      className={cn('input', className, {
        disabled: disabled
      })}
    >
      <Text
        uppercase
        className='input_caption'
      >
        {caption}
      </Text>
      <input
        onChange={handleChange}
        value={value}
        placeholder={placeholder}
        style={{ maxWidth: maxWidthInputItem }}
        disabled={disabled}
        className='input_item'
        type={type}
      />
    </div>
  );
};
