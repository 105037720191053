import { createSlice } from '@reduxjs/toolkit';
import { TOKEN_LOCAL_STORAGE_KEY } from './const';
import { UserSchema } from './types';
import { createUser, initAuthData, loginByUsername } from './api';

const initialState: UserSchema = { authData: null, _inited: false, error: null, isLoading: false };

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, { payload }) => {
      state = state.authData = payload;

      localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, payload.id);
    },

    logout: (state) => {
      state = initialState;
      localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(initAuthData.fulfilled, (state, { payload }) => {
        state.authData = payload;
        state._inited = true;
      })
      .addCase(initAuthData.rejected, (state) => {
        state._inited = true;
      })

      // loginByUsername
      .addCase(loginByUsername.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(loginByUsername.fulfilled, (state, { payload }) => {
        state.authData = payload;
        state.isLoading = false;
      })
      .addCase(loginByUsername.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload ?? 'error';
      })

      // createUser
      .addCase(createUser.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(createUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(createUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload ?? 'error';
      });
  }
});

export const { actions: userActions } = userSlice;
export const { reducer: userReducer } = userSlice;
