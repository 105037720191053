import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TOKEN_LOCAL_STORAGE_KEY } from '../../store/user/const';
// import 'isomorphic-fetch';

export const rtkApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API + 'api',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY) || '';

      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }

      return headers;
    }
  }),
  tagTypes: ['AllUsers', 'CurrentWorkShift'],
  endpoints: () => ({})
});
