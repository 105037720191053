import { createSlice } from '@reduxjs/toolkit';
import { WorkShiftStatus, WorkShiftsSchema } from './types';
import { getWorkShiftsRequests, updateWorkShifts } from './apiOld';

const initialState: WorkShiftsSchema = {
  requests: [],
  error: null,
  isLoading: false,
  current: { id: '0', status: WorkShiftStatus['not-work'] },
  workShifts: []
};

const workShiftsSlice = createSlice({
  name: 'workShifts',
  initialState,
  reducers: {
    setLoading: (state) => {
      state.isLoading = true;
    }
  },
  extraReducers: (builder) => {
    builder
      // getWorkShiftsRequests
      .addCase(getWorkShiftsRequests.pending, (state) => {
        state.error = null;
        // state.isLoading = true;
      })
      .addCase(getWorkShiftsRequests.fulfilled, (state, { payload }) => {
        state.requests = payload;
        state.isLoading = false;
      })
      .addCase(getWorkShiftsRequests.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload ?? 'error';
      })

      // updateWorkShifts
      .addCase(updateWorkShifts.pending, (state) => {
        state.error = null;
        state.isLoading = true;
      })
      .addCase(updateWorkShifts.fulfilled, (state, { payload }) => {
        state.current = payload ?? initialState.current;
        state.isLoading = false;
      })
      .addCase(updateWorkShifts.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload ?? 'error';
      });
  }
});

export const { actions: workShiftsActions } = workShiftsSlice;
export const { reducer: workShiftsReducer } = workShiftsSlice;
