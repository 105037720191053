import { Page } from '../../layouts/page/Page';
import { Text } from '../../components/text/Text';
import { MiniStatus } from '../../components/miniStatus/MiniStatus';
import { formatDate } from '../../utils/formatDate';
import { Loader } from '../../components/loader/Loader';
import { useGetAllWorkShiftsQuery } from '../../store/workShifts/api';
import './HistoryPage.scss';
import { Pagination } from '../../shared/ui/Pagination';
import { useState } from 'react';

export const HistoryPage = () => {
  const [page, setPage] = useState(1);
  const { data, isLoading, error, isFetching } = useGetAllWorkShiftsQuery(page);

  if (isLoading || isFetching) {
    return <Loader />;
  }

  const renderContent = () => {
    if (!data || error || !data.workShifts.length) {
      return null;
    }

    return (
      <>
        <table className='history-page_table'>
          <thead>
            <tr className='history-page_table-header-row'>
              <th className='history-page_table-cell'>
                <Text uppercase>Дата</Text>
              </th>
              <th className='history-page_table-cell'>
                <Text uppercase>ВРЕМЯ СТАРТ</Text>
              </th>
              <th className='history-page_table-cell'>
                <Text uppercase>ВРЕМЯ ФИНИШ</Text>
              </th>
              <th className='history-page_table-cell'>
                <Text uppercase>СТАТУС</Text>
              </th>
            </tr>
          </thead>
          <tbody>
            {data.workShifts.map((workShift) => (
              <tr
                key={workShift.id}
                className='history-page_table-row'
              >
                <td className='history-page_table-cell'>
                  <Text weight={500}>{formatDate(workShift.startDate, 'DD.MM.YYYY')}</Text>
                </td>
                <td className='history-page_table-cell'>
                  <Text weight={500}>{formatDate(workShift.startDate, 'HH:mm')}</Text>
                </td>
                <td className='history-page_table-cell'>
                  <Text weight={500}>{formatDate(workShift.endDate, 'HH:mm')}</Text>
                </td>
                <td className='history-page_table-cell'>
                  <MiniStatus status={workShift.status} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <Pagination
          style={{ marginTop: 15 }}
          defaultPageSize={15}
          total={data.total}
          current={page}
          onChange={setPage}
        />
      </>
    );
  };

  return (
    <Page className='history-page'>
      <Text
        className='history-page_title'
        size={20}
        uppercase
        align='center'
      >
        История
      </Text>

      {error && (
        <Text
          color='red'
          size={20}
          uppercase
          align='center'
        >
          Произошла ошибка
        </Text>
      )}

      {!error && !data?.workShifts.length && (
        <Text
          size={20}
          align='center'
        >
          Вы еще не работали
        </Text>
      )}

      {renderContent()}
    </Page>
  );
};
