import './RegisterPage.scss';
import { Page } from '../../layouts/page/Page';
import { Text } from '../../components/text/Text';
import { Input } from '../../components/input/Input';
import { Button } from '../../components/button/Button';
import { Select } from '../../components/select/Select';
import { useState } from 'react';
import { useAppDispatch } from '../../store/useAppDispatch';
import { createUser } from '../../store/user/api';
import { UserRole } from '../../store/user/roles';
import Validation from '../../utils/Validation';
import { useUserError, useUserLoading } from '../../store/user/selectors';

export const RegisterPage = () => {
  const dispatch = useAppDispatch();

  const [kisArt, setKisArt] = useState('');
  const [role, setRole] = useState<UserRole>(UserRole.driver);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const [kisArtError, setKisArtError] = useState('');
  const [password2Error, setPassword2Error] = useState('');
  const [emailError, setEmailError] = useState('');
  const [newUserMessage, setNewUserMessage] = useState('');

  const userError = useUserError();
  const isLoading = useUserLoading();

  const isValidForm =
    kisArt && name && phone && email && password && password2 && !isLoading && !kisArtError && !password2Error && !emailError;

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setNewUserMessage('');

    const result = await dispatch(
      createUser({
        kisArt,
        name,
        phone,
        email,
        password,
        role
      })
    );

    if (result.meta.requestStatus === 'fulfilled') {
      setNewUserMessage('Пользователь создан');
      setKisArt('');
      setRole(UserRole.driver);
      setName('');
      setPhone('');
      setEmail('');
      setPassword('');
      setPassword2('');
    }
  };

  const handleInputKisArt = (value: string) => {
    setKisArtError(Validation.kisArt(value));
    setKisArt(value);
  };

  const handleInputEmail = (value: string) => {
    setEmailError(Validation.email(value));
    setEmail(value);
  };

  const handleInputPassword2 = (value: string) => {
    setPassword2(value);

    if (!password || value !== password) {
      setPassword2Error('Пароли не совпадают');
    } else {
      setPassword2Error('');
    }
  };

  return (
    <Page className='register-page'>
      <div className='register-page_wrapper'>
        <Text
          className='register-page_title'
          size={20}
          uppercase
          align='center'
        >
          Регистрация
        </Text>

        <form
          className='register-page_form'
          onSubmit={handleSubmitForm}
        >
          <Input
            value={kisArt}
            onChange={handleInputKisArt}
            caption='КИС-АРТ / логин'
          />
          {kisArtError && <Text color='red'>{kisArtError}</Text>}

          <Select
            name='role'
            caption='Роль'
            value={role}
            onChange={setRole}
            list={[
              { name: 'Водитель', value: UserRole.driver },
              { name: 'Медик', value: UserRole.medical },
              { name: 'Механик', value: UserRole.technical }
            ]}
          />

          <Input
            value={name}
            onChange={setName}
            caption='ФИО'
          />
          <Input
            value={phone}
            onChange={setPhone}
            caption='Телефон'
          />
          <Input
            value={email}
            onChange={handleInputEmail}
            caption='e-mail'
          />
          {emailError && <Text color='red'>{emailError}</Text>}

          <Input
            value={password}
            onChange={setPassword}
            caption='Пароль'
          />
          <Input
            value={password2}
            onChange={handleInputPassword2}
            caption='Повторите пароль'
          />
          {password2Error && <Text color='red'>{password2Error}</Text>}

          {userError && <Text color='red'>{userError}</Text>}
          {newUserMessage && <Text color='green'>{newUserMessage}</Text>}

          <Button
            type='submit'
            theme='black'
            disabled={!isValidForm}
          >
            Зарегистрировать
          </Button>
        </form>
      </div>
    </Page>
  );
};
