import './ProfilePage.scss';
import { Page } from '../../layouts/page/Page';
import { Text } from '../../components/text/Text';

import { useAuthData } from '../../store/user/selectors';

export const ProfilePage = () => {
  const authData = useAuthData();

  const renderItem = (caption: string, value?: string) => {
    if (!value) {
      return null;
    }

    return (
      <div className='profile-page_row'>
        <Text
          uppercase
          size={20}
        >
          {caption}
        </Text>
        <Text
          uppercase
          weight={500}
        >
          {value}
        </Text>
      </div>
    );
  };

  return (
    <Page className='profile-page'>
      <Text
        className='profile-page_title'
        size={20}
        uppercase
        align='center'
      >
        Личные данные
      </Text>

      {renderItem('KIS ART:', authData?.kisArt)}
      {renderItem('ФИО:', authData?.name)}
      {renderItem('Телефон:', authData?.phone)}
      {renderItem('E-mail:', authData?.email)}
    </Page>
  );
};
