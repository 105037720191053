import cn from 'classnames';
import './Text.scss';
import { ReactNode } from 'react';

export interface ITextProps {
  className?: string;
  children: ReactNode;
  align?: 'left' | 'center' | 'right';
  color?: 'black' | 'green' | 'white' | 'red';
  size?: 12 | 16 | 20;
  weight?: 400 | 500 | 700;
  underline?: boolean;
  uppercase?: boolean;
}

export const Text = (props: ITextProps) => {
  const { className, children, align, color, size, underline, uppercase, weight } = props;

  return (
    <p
      style={{
        textAlign: align,
        fontSize: size,
        fontWeight: weight,
        textDecoration: underline ? 'underline' : undefined,
        textTransform: uppercase ? 'uppercase' : undefined,
        color
      }}
      className={cn('text', className, {})}
    >
      {children}
    </p>
  );
};
