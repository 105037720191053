import cn from 'classnames';
import { Text } from '../text/Text';
import { formatDate } from '../../utils/formatDate';
import './Inspection.scss';

import medicalIcon from '../../assets/img/medical.png';
import technicalIcon from '../../assets/img/technical.png';
import { WorkShift } from '../../store/workShifts/types';

interface IInspectionProps {
  className?: string;
  currentWorkShift: WorkShift;
}

export const Inspection = (props: IInspectionProps) => {
  const { className, currentWorkShift } = props;

  const { startMedicalDate, startTechnicalDate, endMedicalDate, endTechnicalDate } = currentWorkShift;

  const emptyCell = <td className='inspection_cell' />;

  const completeText = (
    <Text
      color='green'
      uppercase
    >
      Пройден
    </Text>
  );

  const getTimeItem = (time?: Date) => (
    <Text
      size={20}
      uppercase
    >
      {formatDate(time, 'HH:mm') || '__ : __'}
    </Text>
  );

  const getCompleteRow = ({ medical = false, technical = false }) => (
    <tr className='inspection_row inspection_row__complete'>
      <td className='inspection_cell'>{medical && completeText}</td>
      {emptyCell}
      <td className='inspection_cell'>{technical && completeText}</td>
    </tr>
  );

  const getTimeRow = (text: string, { startTime, endTime }: { startTime?: Date; endTime?: Date }) => (
    <tr className='inspection_row'>
      <td className='inspection_cell'>{getTimeItem(startTime)}</td>
      <td className='inspection_cell'>
        <Text uppercase>{text}</Text>
      </td>
      <td className='inspection_cell'>{getTimeItem(endTime)}</td>
    </tr>
  );

  return (
    <table className={cn('inspection', className)}>
      <thead>
        <tr className='inspection_row inspection_row__head'>
          <th className='inspection_cell'>
            <img
              className='inspection_icon'
              src={medicalIcon}
              alt=''
            />
            <Text uppercase>Медосмотр</Text>
          </th>
          {emptyCell}
          <th className='inspection_cell'>
            <img
              className='inspection_icon'
              src={technicalIcon}
              alt=''
            />
            <Text uppercase>Техосмотр</Text>
          </th>
        </tr>
      </thead>
      <tbody>
        {getCompleteRow({ medical: !!startMedicalDate, technical: !!startTechnicalDate })}

        {getTimeRow('Начало смены', { startTime: startMedicalDate, endTime: startTechnicalDate })}

        {getCompleteRow({ medical: !!endMedicalDate, technical: !!endTechnicalDate })}

        {getTimeRow('Завершение смены', { startTime: endMedicalDate, endTime: endTechnicalDate })}
      </tbody>
    </table>
  );
};
