import { useEffect, useState } from 'react';
import moment from 'moment';
import { Text } from '../../components/text/Text';
import { Button } from '../../components/button/Button';
import { Modal } from '../../components/modal/Modal';
import { Input } from '../../components/input/Input';
import { UserRole } from '../../store/user/roles';
import { WorkShiftRequest, WorkShiftStatus } from '../../store/workShifts/types';
import { formatDate } from '../../utils/formatDate';
import { getDate } from './RequestPage';
import './RequestPage.scss';

interface IRequestPageModalProps {
  role: UserRole.medical | UserRole.technical;
  onSaveTime: (startTime: string, endTime: string) => void;
  onClose: () => void;
  workShift: WorkShiftRequest;
}

export const RequestPageModal = (props: IRequestPageModalProps) => {
  const { role, onClose, onSaveTime, workShift } = props;

  const isMedical = role === UserRole.medical;
  const requestName = isMedical ? 'медостмотр' : 'техосмотр';

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  useEffect(() => {
    if (isMedical) {
      setStartTime(formatDate(workShift.startMedicalDate, 'HH:mm'));
      setEndTime(formatDate(workShift.endMedicalDate, 'HH:mm'));
    } else {
      setStartTime(formatDate(workShift.startTechnicalDate, 'HH:mm'));
      setEndTime(formatDate(workShift.endTechnicalDate, 'HH:mm'));
    }
  }, []);

  const isDisabledStartTime = (): boolean => {
    if (workShift.status === WorkShiftStatus.working) {
      return true;
    }

    if (workShift.status === WorkShiftStatus.result) {
      return true;
    }

    if (isMedical ? !!workShift.startMedicalDate : !!workShift.startTechnicalDate) {
      return true;
    }

    return false;
  };

  const isDisabledEndTime = (): boolean => {
    if (workShift.status === WorkShiftStatus['pending-start']) {
      return true;
    }

    if (workShift.status === WorkShiftStatus.working) {
      return true;
    }

    if (workShift.status === WorkShiftStatus.result) {
      return true;
    }

    if (
      isMedical
        ? !workShift.startMedicalDate || !!workShift.endMedicalDate
        : !workShift.startTechnicalDate || !!workShift.endTechnicalDate
    ) {
      return true;
    }

    return false;
  };

  const isDisabledSaveButton = (): boolean => {
    if (workShift.status === WorkShiftStatus.working) {
      return true;
    }

    if (workShift.status === WorkShiftStatus.result) {
      return true;
    }

    if (workShift.status === WorkShiftStatus['pending-start'] && !startTime) {
      return true;
    }

    if (workShift.status === WorkShiftStatus['pending-end']) {
      const startDate = isMedical ? workShift.startMedicalDate : workShift.startTechnicalDate;

      if (!endTime || !startDate) {
        return true;
      }

      if (getDate(endTime).isBefore(moment(startDate))) {
        return true;
      }
    }

    return false;
  };

  return (
    <Modal
      className='request-page_modal'
      isOpen={true}
      onClose={onClose}
    >
      <Text
        className='request-page_title'
        size={20}
        uppercase
      >
        Заявка на {requestName}
      </Text>

      <div className='request-page_modal-name'>
        <Text uppercase>фио</Text>
        <Text>{workShift.name}</Text>
      </div>

      <Input
        value={startTime}
        onChange={setStartTime}
        className='request-page_modal-input'
        caption={`Время ${requestName}а начала работы`}
        maxWidthInputItem={120}
        type='time'
        disabled={isDisabledStartTime()}
      />

      <Input
        value={endTime}
        onChange={setEndTime}
        className='request-page_modal-input'
        caption={`Время ${requestName}а КОНЦА работы`}
        maxWidthInputItem={120}
        type='time'
        disabled={isDisabledEndTime()}
      />

      <Button
        disabled={isDisabledSaveButton()}
        theme='yellow'
        className='request-page_modal-save-button'
        onClick={() => onSaveTime(startTime, endTime)}
      >
        Сохранить
      </Button>
    </Modal>
  );
};
