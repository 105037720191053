import { createAsyncThunk } from '@reduxjs/toolkit';
import { WorkShift, WorkShiftRequest } from './types';
import { workShiftsActions } from './workShiftsSlice';
import { ThunkConfig } from '../../app/providers/StoreProvider';

export const getWorkShiftsRequests = createAsyncThunk<
  WorkShiftRequest[],
  { type: 'technical' | 'medical'; isLoading?: boolean },
  ThunkConfig
>('workShifts/getWorkShiftsRequests', async (data, thunkApi) => {
  const { rejectWithValue, dispatch, extra } = thunkApi;

  if (data.isLoading !== false) {
    dispatch(workShiftsActions.setLoading());
  }

  try {
    const response = await extra.api.get('/api/workShift/request', { params: { type: data.type } });

    if (!response.data) {
      throw new Error();
    }

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.message ?? 'error');
  }
});

export const updateWorkShifts = createAsyncThunk<WorkShift, WorkShift, ThunkConfig>(
  'workShifts/updateWorkShifts',
  async (data, thunkApi) => {
    const { rejectWithValue, extra } = thunkApi;

    const { userId, ...otherData } = data;

    try {
      const response = await extra.api.patch(`/api/workShift/${userId}`, otherData);

      if (!response.data) {
        throw new Error();
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.message ?? 'error');
    }
  }
);
