import cn from 'classnames';
import './Logo.scss';

import logo from '../../assets/img/logo.png';

interface ILogoProps {
  className?: string;
}

export const Logo = (props: ILogoProps) => {
  const { className } = props;

  return (
    <div className={cn('logo', className)}>
      <a href='/'>
        <img
          className='logo-img'
          src={logo}
          alt='Логотип'
        />
      </a>
    </div>
  );
};
