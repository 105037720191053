import { rtkApi } from '../../../shared/api/rtkApi';
import { IUser } from '../../../store/user/types';
import { WorkShift } from '../../../store/workShifts/types';

const userApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query<{ list: IUser[]; total: number }, number>({
      query: (page) => ({
        url: '/users',
        method: 'GET',
        params: {
          page,
          limit: 15
        }
      }),
      providesTags: () => ['AllUsers']
    }),

    changeKisArtStatus: builder.mutation<void, { userId: number; newStatus: boolean }>({
      query: ({ userId, newStatus }) => ({
        url: `/user/${userId}`,
        method: 'PATCH',
        body: {
          newStatus
        }
      }),
      invalidatesTags: ['AllUsers']
    }),

    getWorkShiftHistoryByUserId: builder.query<{ workShifts: WorkShift[]; total: number }, { userId: number; page: number }>({
      query: ({ userId, page }) => ({
        url: `/workShift/${userId}`,
        params: {
          limit: 15,
          page
        }
      })
    })
  })
});

export const { useGetWorkShiftHistoryByUserIdQuery } = userApi;

export const useGetAllUsers = userApi.useGetAllUsersQuery;
export const useChangeKisArtStatus = userApi.useChangeKisArtStatusMutation;
