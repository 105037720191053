import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Page } from '../../layouts/page/Page';
import { Text } from '../../components/text/Text';
import { Button } from '../../components/button/Button';
import editIcon from '../../assets/img/edit.png';
import { useAppDispatch } from '../../store/useAppDispatch';
import { getWorkShiftsRequests, updateWorkShifts } from '../../store/workShifts/apiOld';
import { useIsLoading, useRequestList, useWorkShiftsError } from '../../store/workShifts/selectors';
import { UserRole } from '../../store/user/roles';
import { MiniStatus } from '../../components/miniStatus/MiniStatus';
import { WorkShiftRequest, WorkShiftStatus } from '../../store/workShifts/types';
import { formatDate } from '../../utils/formatDate';
import { RequestPageModal } from './RequestPageModal';
import { Loader } from '../../components/loader/Loader';
import './RequestPage.scss';
import { Table } from '../../shared/ui/Table';
import { IColumn } from '../../shared/ui/Table/Table';

const REQUEST_TIME_MIN = 1;

interface IRequestPageProps {
  role: UserRole.medical | UserRole.technical;
}

let intervalId: any = null;

export const getDate = (time: string): moment.Moment => {
  const [hours, min] = time.split(':');

  return moment().hours(+hours).minutes(+min);
};

export const RequestPage = (props: IRequestPageProps) => {
  const { role } = props;

  const dispatch = useAppDispatch();

  const isMedical = role === UserRole.medical;
  const requestName = isMedical ? 'медостмотр' : 'техосмотр';

  const [openedWorkShift, setOpenedWorkShift] = useState<WorkShiftRequest | null>(null);

  const requestList = useRequestList();
  const error = useWorkShiftsError();
  const isLoading = useIsLoading();

  // Запрос запросов
  useEffect(() => {
    dispatch(getWorkShiftsRequests({ type: role }));

    intervalId = setInterval(() => {
      dispatch(getWorkShiftsRequests({ type: role, isLoading: false }));
    }, 60_000 * REQUEST_TIME_MIN);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, []);

  const handleOpenModal = useCallback(
    (workShift: WorkShiftRequest) => () => {
      setOpenedWorkShift(workShift);
    },
    []
  );

  const handleCloseModal = useCallback(() => {
    setOpenedWorkShift(null);
  }, []);

  const handleSaveTime = async (startTime: string, endTime: string) => {
    if (!openedWorkShift) {
      return;
    }

    const isPendingStart = openedWorkShift.status === WorkShiftStatus['pending-start'];
    const isPendingEnd = openedWorkShift.status === WorkShiftStatus['pending-end'];

    // Медик
    if (isMedical) {
      if (isPendingStart && !!startTime) {
        await dispatch(
          updateWorkShifts({
            id: openedWorkShift.id,
            userId: openedWorkShift.userId,
            status: WorkShiftStatus['pending-start'],
            startMedicalDate: getDate(startTime).toDate()
          })
        );
      }

      if (isPendingEnd && !!endTime) {
        await dispatch(
          updateWorkShifts({
            id: openedWorkShift.id,
            userId: openedWorkShift.userId,
            status: WorkShiftStatus['pending-end'],
            endMedicalDate: getDate(endTime).toDate()
          })
        );
      }
      // Механик
    } else {
      if (isPendingStart && !!startTime) {
        await dispatch(
          updateWorkShifts({
            id: openedWorkShift.id,
            userId: openedWorkShift.userId,
            status: WorkShiftStatus.working,
            startTechnicalDate: getDate(startTime).toDate()
          })
        );
      }

      if (isPendingEnd && !!endTime) {
        await dispatch(
          updateWorkShifts({
            id: openedWorkShift.id,
            userId: openedWorkShift.userId,
            status: WorkShiftStatus.result,
            endTechnicalDate: getDate(endTime).toDate()
          })
        );
      }
    }

    dispatch(getWorkShiftsRequests({ type: role }));
    handleCloseModal();
  };

  const isDisabledEditButton = (workShift: WorkShiftRequest): boolean => {
    if (workShift.status === WorkShiftStatus.result) {
      return true;
    }

    if (workShift.status === WorkShiftStatus.working) {
      return true;
    }

    // Медик
    if (isMedical) {
      // Если на начальном ожидании уже есть дата начала
      if (workShift.startMedicalDate && workShift.status === WorkShiftStatus['pending-start']) {
        return true;
      }

      // Если на конечном ожидании уже есть дата конца
      if (workShift.endMedicalDate && workShift.status === WorkShiftStatus['pending-end']) {
        return true;
      }
    }

    return false;
  };

  if (isLoading) {
    return <Loader />;
  }

  const getColumns = (): IColumn<WorkShiftRequest>[] => {
    const columns: IColumn<WorkShiftRequest>[] = [
      {
        name: 'ID',
        getContent: ({ id }) => id
      },
      {
        name: 'Дата',
        getContent: ({ date }) => formatDate(date, 'DD.MM.YYYY')
      },
      {
        name: 'КИС-АРТ',
        getContent: ({ kisArt }) => kisArt
      },
      {
        name: 'ФИО',
        getContent: ({ name }) => name
      },
      {
        name: 'Телефон',
        getContent: ({ phone }) => phone
      },
      {
        name: 'Время старт',
        getContent: ({ startMedicalDate, startTechnicalDate }) => {
          return formatDate(isMedical ? startMedicalDate : startTechnicalDate, 'HH:mm');
        }
      },
      {
        name: 'Время финиш',
        getContent: ({ endMedicalDate, endTechnicalDate }) => {
          return formatDate(isMedical ? endMedicalDate : endTechnicalDate, 'HH:mm');
        }
      },
      {
        name: 'Статус',
        getContent: ({ status }) => <MiniStatus status={status} />
      },
      {
        name: '',
        getContent: (row) => (
          <Button
            disabled={isDisabledEditButton(row)}
            onClick={handleOpenModal(row)}
            className='request-page_edit-button'
            icon={editIcon}
          />
        )
      }
    ];

    if (!isMedical) {
      columns.splice(
        5,
        0,
        {
          name: 'ПРОБЕГ СТАРТ',
          getContent: ({ startCarMileage }) => startCarMileage
        },
        {
          name: 'ПРОБЕГ ФИНИШ',
          getContent: ({ endCarMileage }) => endCarMileage
        }
      );
    }

    return columns;
  };

  const renderContent = () => {
    if (error || !requestList.length) {
      return null;
    }

    return (
      <Table
        rows={requestList}
        columns={getColumns()}
      />
    );
  };

  return (
    <Page className='request-page'>
      <Text
        className='request-page_title'
        size={20}
        uppercase
      >
        Заявки на {requestName}
      </Text>

      {error && (
        <Text
          color='red'
          size={20}
          uppercase
          align='center'
        >
          Произошла ошибка
        </Text>
      )}

      {!error && !requestList.length && (
        <Text
          size={20}
          align='center'
        >
          Нет активных заявок
        </Text>
      )}

      {renderContent()}

      {openedWorkShift && (
        <RequestPageModal
          workShift={openedWorkShift}
          onClose={handleCloseModal}
          onSaveTime={handleSaveTime}
          role={role}
        />
      )}
    </Page>
  );
};
