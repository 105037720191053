export enum UserRole {
  'admin' = 'admin',
  'driver' = 'driver',
  'medical' = 'medical',
  'technical' = 'technical'
}

export const isAdmin = (role: UserRole): boolean => role === UserRole.admin;
export const isDriver = (role: UserRole): boolean => role === UserRole.driver;
export const isMedical = (role: UserRole): boolean => role === UserRole.medical;
export const isTechnical = (role: UserRole): boolean => role === UserRole.technical;
