import cn from 'classnames';
import { Text } from '../text/Text';
import { WorkShiftStatus } from '../../store/workShifts/types';
import './MiniStatus.scss';

interface IMiniStatusProps {
  className?: string;
  status: WorkShiftStatus;
  statusText?: Record<Partial<WorkShiftStatus>, string>;
}

export const MiniStatus = (props: IMiniStatusProps) => {
  const { className, status, statusText } = props;

  const mapStatusText: Record<WorkShiftStatus, string> = {
    'not-work': 'Завершено',
    'pending-start': 'Ожидание',
    working: 'В пути',
    'pending-end': 'Ожидание',
    result: 'Завершено',
    complete: 'Завершено',
    ...statusText
  };

  const isBlackTextColor = status === WorkShiftStatus['pending-start'] || status === WorkShiftStatus['pending-end'];

  return (
    <div className={cn('mini-status', `mini-status__${status}`, className)}>
      <Text
        weight={400}
        color={isBlackTextColor ? 'black' : 'white'}
      >
        {mapStatusText[status]}
      </Text>
    </div>
  );
};
