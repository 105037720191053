import cn from 'classnames';
import { Text } from '../text/Text';
import { WorkShiftStatus } from '../../store/workShifts/types';
import './AppStatus.scss';

interface IAppStatusProps {
  className?: string;
  status: WorkShiftStatus;
}

export const AppStatus = (props: IAppStatusProps) => {
  const { className, status } = props;

  const mapStatusText: Record<WorkShiftStatus, string> = {
    'not-work': 'Вы отдыхаете',
    'pending-start': 'Ожидаем',
    working: 'В пути',
    'pending-end': 'Ожидаем',
    result: 'Вы отдыхаете',
    complete: 'Вы отдыхаете'
  };

  const isBlackTextColor = status === WorkShiftStatus['pending-start'] || status === WorkShiftStatus['pending-end'];

  return (
    <div className={cn('app-status', `app-status__${status}`, className)}>
      <Text
        size={20}
        uppercase
        color={isBlackTextColor ? 'black' : 'white'}
      >
        {mapStatusText[status]}
      </Text>
    </div>
  );
};
