import './RecoverPage.scss';
import { Page } from '../../layouts/page/Page';
import { Text } from '../../components/text/Text';
import { Input } from '../../components/input/Input';
import { Button } from '../../components/button/Button';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/useAppDispatch';
import { recoverPassword } from '../../store/user/api';
import validation from '../../utils/Validation';
import { Loader } from '../../components/loader/Loader';

export const RecoverPage = () => {
  const dispatch = useAppDispatch();

  const [kisArt, setKisArt] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const [kisArtError, setKisArtError] = useState('');
  const [password2Error, setPassword2Error] = useState('');
  const [emailError, setEmailError] = useState('');
  const [recoverPasswordMessage, setRecoverPasswordMessage] = useState('');

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setEmailError('');
    setError('');
  }, [kisArt]);

  useEffect(() => {
    setKisArtError('');
    setError('');
  }, [email]);

  const isValidForm =
    (!!kisArt || !!email) && !!password && password === password2 && !kisArtError && !password2Error && !emailError;

  const handleInputKisArt = (value: string) => {
    if (!value) {
      setKisArtError('');
    } else {
      setKisArtError(validation.kisArt(value));
    }

    setRecoverPasswordMessage('');
    setKisArt(value);
  };

  const handleInputEmail = (value: string) => {
    if (!value) {
      setEmailError('');
    } else {
      setEmailError(validation.email(value));
    }

    setRecoverPasswordMessage('');
    setEmail(value);
  };

  const handleInputPassword2 = (value: string) => {
    setPassword2(value);

    if (!password || value !== password) {
      setPassword2Error('Пароли не совпадают');
    } else {
      setPassword2Error('');
    }
  };

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setIsLoading(true);
    setError('');
    setRecoverPasswordMessage('');

    const result = await dispatch(recoverPassword({ kisArt, email, password }));

    if (result.meta.requestStatus === 'fulfilled') {
      setIsLoading(false);

      setRecoverPasswordMessage(`Новый пароль "${password}"`);

      setKisArt('');
      setEmail('');
      setPassword('');
      setPassword2('');
    }

    if (result.meta.requestStatus === 'rejected') {
      setIsLoading(false);

      const message = result.payload as string;
      setError(message);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Page className='recover-page'>
      <div className='recover-page_wrapper'>
        <Text
          className='recover-page_title'
          size={20}
          uppercase
          align='center'
        >
          Восстановить пароль пользователя
        </Text>

        <Text>Заполните одно или несколько полей</Text>

        <form
          onSubmit={handleSubmitForm}
          className='recover-page_form'
        >
          <Input
            value={kisArt}
            onChange={handleInputKisArt}
            caption='КИС-АРТ'
          />
          {kisArtError && <Text color='red'>{kisArtError}</Text>}

          <Input
            value={email}
            onChange={handleInputEmail}
            caption='e-mail'
          />
          {emailError && <Text color='red'>{emailError}</Text>}

          <hr />

          <Input
            value={password}
            onChange={setPassword}
            caption='Новый пароль'
          />
          <Input
            value={password2}
            onChange={handleInputPassword2}
            caption='Повторите пароль'
          />
          {password2Error && <Text color='red'>{password2Error}</Text>}

          {error && <Text color='red'>{error}</Text>}
          {recoverPasswordMessage && <Text color='green'>Пароль изменен.</Text>}
          {recoverPasswordMessage && <Text color='green'>{recoverPasswordMessage}</Text>}

          <Button
            type='submit'
            theme='black'
            disabled={!isValidForm}
          >
            Восстановить пароль
          </Button>
        </form>
      </div>
    </Page>
  );
};
