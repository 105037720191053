import { rtkApi } from '../../shared/api/rtkApi';
import { WorkShift } from './types';

const driverApi = rtkApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentWorkShift: builder.query<WorkShift, null>({
      query: () => ({
        url: '/workShift',
        params: {
          type: 'current'
        }
      }),
      providesTags: () => ['CurrentWorkShift']
    }),

    getAllWorkShifts: builder.query<{ workShifts: WorkShift[]; total: number }, number>({
      query: (page) => ({
        url: '/workShift',
        params: {
          limit: 15,
          page
        }
      }),
      providesTags: () => ['CurrentWorkShift']
    }),

    updateCurrentWorkShift: builder.mutation<null, Partial<WorkShift>>({
      query: (data) => ({
        url: '/workShift',
        method: 'PATCH',
        body: data
      }),
      invalidatesTags: ['CurrentWorkShift']
    }),

    createWorkShift: builder.mutation<null, { startCarMileage: number }>({
      query: (data) => ({
        url: '/workShift',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['CurrentWorkShift']
    })
  })
});

export const {
  useGetCurrentWorkShiftQuery,
  useUpdateCurrentWorkShiftMutation,
  useCreateWorkShiftMutation,
  useGetAllWorkShiftsQuery
} = driverApi;
