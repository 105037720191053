export enum AppRoutes {
  MAIN = 'main',
  PROFILE = 'profile',
  HISTORY = 'history',
  LOGIN = 'login',
  REGISTER = 'register',
  RECOVER = 'recover',

  // LAST
  NOT_FOUND = 'not_found'
}

export const getRouteMain = () => '/';
export const getRouteProfile = () => '/profile';
export const getRouteHistory = () => '/history';
export const getRouteLogin = () => '/login';
export const getRouteRegister = () => '/register';
export const getRouteRecover = () => '/recover';
