import './AuthPage.scss';
import { Page } from '../../layouts/page/Page';
import { Text } from '../../components/text/Text';
import { Input } from '../../components/input/Input';
import { Button } from '../../components/button/Button';
import { useState } from 'react';
import { useAppDispatch } from '../../store/useAppDispatch';
import { loginByUsername } from '../../store/user/api';
import { useUserError, useUserLoading } from '../../store/user/selectors';
import validation from '../../utils/Validation';

export const AuthPage = () => {
  const dispatch = useAppDispatch();

  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [kisArtError, setKisArtError] = useState('');

  const error = useUserError();
  const isLoading = useUserLoading();

  const handleInputKisArt = (value: string) => {
    setLogin(value);
    setKisArtError(validation.kisArt(value));
  };

  const handleSubmitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(loginByUsername({ login, password }));

    setLogin('');
    setPassword('');
  };

  return (
    <Page className='auth-page'>
      <div className='auth-page_wrapper'>
        <Text
          className='auth-page_title'
          size={20}
          uppercase
          align='center'
        >
          Личный кабинет
        </Text>

        <form
          onSubmit={handleSubmitForm}
          className='auth-page_form'
        >
          <Input
            caption='ВАШ КИС-АРТ / логин'
            value={login}
            onChange={handleInputKisArt}
          />

          {kisArtError && <Text color='red'>{kisArtError}</Text>}

          <Input
            caption='Пароль'
            value={password}
            onChange={setPassword}
            type='password'
          />

          {error && <Text color='red'>Неверный логин или пароль</Text>}

          <Button
            theme='black'
            type='submit'
            disabled={isLoading || !!kisArtError || !login || !password}
          >
            Войти
          </Button>

          {/* <Button type='button'>Забыли пароль</Button> */}
        </form>
      </div>
    </Page>
  );
};
