import { memo, Suspense, useCallback } from 'react';
import { Route, Routes } from 'react-router';
import { IsAuth, RequireAuth } from './RequireAuth';
import { routeConfig } from '../config/routeConfig';
import { AppRoutesProps } from '../../../../shared/types/router';

export const AppRouter = memo(() => {
  const renderWithWrapper = useCallback((route: AppRoutesProps) => {
    // const element = <Suspense fallback={<LoaderPage />}>{route.element}</Suspense>;

    let element = route.element;

    if (route.authOnly) {
      element = <RequireAuth roles={route.roles}>{route.element as JSX.Element}</RequireAuth>;
    }

    if (route.exceptAuth) {
      element = <IsAuth roles={route.roles}>{route.element as JSX.Element}</IsAuth>;
    }

    return (
      <Route
        key={route.path}
        path={route.path}
        element={element}
      />
    );
  }, []);

  return (
    // <Suspense fallback={<LoaderPage />}>
    <Routes>{Object.values(routeConfig).map(renderWithWrapper)}</Routes>
    // </Suspense>
  );
});
