import { WorkShiftStatus } from './types';

export function useWorkShiftStatus(status?: string) {
  if (!status) {
    return {};
  }

  return {
    isNotWorkStatus: status === WorkShiftStatus['not-work'],
    isPendingStartStatus: status === WorkShiftStatus['pending-start'],
    isWorkingStatus: status === WorkShiftStatus.working,
    isPendingEndStatus: status === WorkShiftStatus['pending-end'],
    isResultStatus: status === WorkShiftStatus.result,
    isCompleteStatus: status === WorkShiftStatus.complete
  };
}
