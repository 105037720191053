import { ReactNode, useState } from 'react';
import cn from 'classnames';
import cls from './Checkbox.module.scss';

interface CheckboxProps {
  className?: string;
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  disabled?: boolean;
}

export function Checkbox<T>(props: CheckboxProps) {
  const { className, onChange, isChecked, disabled } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;

    onChange(checked);
  };

  return (
    <input
      disabled={disabled}
      type='checkbox'
      checked={isChecked}
      onChange={handleChange}
    />
  );
}
