import axios from 'axios';
import { TOKEN_LOCAL_STORAGE_KEY } from '../../store/user/const';

const { REACT_APP_API } = process.env;

export const $api = axios.create({
  baseURL: REACT_APP_API
});

$api.interceptors.request.use((config) => {
  const token = localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY) ?? '';

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});
