import { ReactElement } from 'react';
import { UserRole } from '../../../../store/user/roles';
import { DriverWorkPage } from '../../../../pages/driverWork/DriverWorkPage';
import { RequestPage } from '../../../../pages/request/RequestPage';
import { useRole } from '../../../../store/user/selectors';
import { AdminPage } from '../../../../pages/admin/AdminPage';

const mapMainPage: Record<UserRole, ReactElement> = {
  admin: <AdminPage />,
  driver: <DriverWorkPage />,
  medical: <RequestPage role={UserRole.medical}></RequestPage>,
  technical: <RequestPage role={UserRole.technical}></RequestPage>
};

export function MainPageByRole() {
  const role = useRole();

  if (!role) {
    return null;
  }

  return mapMainPage[role];
}
