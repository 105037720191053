import cn from 'classnames';
import './Button.scss';
import { MouseEventHandler, ReactNode } from 'react';
import { Text } from '../text/Text';

interface IButtonProps {
  className?: string;
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  text?: string | number;
  theme?: 'normal' | 'yellow' | 'black';
  icon?: string;
  iconAlt?: string;
  type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
  disabled?: boolean;
  style?: React.CSSProperties;
  // buttonProps?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

export const Button = (props: IButtonProps) => {
  const { className, children, text, theme = 'normal', icon, iconAlt, onClick, type = 'button', disabled, style } = props;

  const content = text ?? children;

  return (
    <button
      style={style}
      className={cn('button', className, theme, { 'button_with-text': content })}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {icon && (
        <div className={cn('button_icon')}>
          <img
            src={icon}
            alt={iconAlt}
          />
        </div>
      )}

      {icon ? (
        <Text
          size={12}
          uppercase
        >
          {content}
        </Text>
      ) : (
        <Text>{content}</Text>
      )}
    </button>
  );
};
