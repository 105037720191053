import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from '../../../../store/user/userSlice';
import { workShiftsReducer } from '../../../../store/workShifts/workShiftsSlice';
import { $api } from '../../../../shared/api/api';
import { rtkApi } from '../../../../shared/api/rtkApi';

const { NODE_ENV } = process.env;

export const store = configureStore({
  reducer: {
    user: userReducer,
    workShifts: workShiftsReducer,
    [rtkApi.reducerPath]: rtkApi.reducer
  },
  devTools: NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      thunk: {
        extraArgument: {
          api: $api
        }
      }
    }).concat(rtkApi.middleware)
});

export type AppDispatch = (typeof store)['dispatch'];
