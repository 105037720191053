import { UserRole } from '../../../store/user/roles';

export function getRoleName(role: UserRole): string {
  switch (role) {
    case UserRole.admin:
      return 'Админ';
    case UserRole.driver:
      return 'Водитель';
    case UserRole.medical:
      return 'Медик';
    case UserRole.technical:
      return 'Механик';
  }
}
