class Validation {
  kisArt(value: string): string {
    if (!value.startsWith('0')) {
      return 'КИС-АРТ должен начинаться с 0';
    }

    if (/\D/g.test(value) || value.length !== 10) {
      return 'КИС-АРТ должен состоять из 10 цифр';
    }

    return '';
  }

  email(value: string) {
    if (!/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/.test(value)) {
      return 'Неверный формат email';
    }

    return '';
  }
}

export default new Validation();
