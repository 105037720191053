import { createAsyncThunk } from '@reduxjs/toolkit';
import { ILoginUser, INewUser, IRecoverPassword, IUser } from './types';
import { TOKEN_LOCAL_STORAGE_KEY } from './const';
import { ThunkConfig } from '../../app/providers/StoreProvider';
import { rtkApi } from '../../shared/api/rtkApi';
import { WorkShift } from '../workShifts/types';

/** Авторизация по токену */
export const initAuthData = createAsyncThunk<IUser, void, ThunkConfig>('user/initAuthData', async (_, thunkApi) => {
  const { rejectWithValue, extra } = thunkApi;

  const token = localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY) ?? null;
  if (!token) {
    return;
  }

  try {
    const response = await extra.api.get('/api/auth');

    if (!response.data) {
      throw new Error();
    }

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.message ?? 'error');
  }
});

/** Вход */
export const loginByUsername = createAsyncThunk<IUser, ILoginUser, ThunkConfig>(
  'user/loginByUsername',
  async (data, thunkApi) => {
    const { dispatch, rejectWithValue, extra } = thunkApi;

    try {
      const response = await extra.api.post('/api/login', { kisArt: data.login, password: data.password });

      if (!response.data) {
        throw new Error();
      }

      localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, response.data);
      dispatch(initAuthData());

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.message ?? 'error');
    }
  }
);

/** Создать нового пользователя */
export const createUser = createAsyncThunk<IUser, INewUser, ThunkConfig>('user/createUser', async (data, thunkApi) => {
  const { rejectWithValue, extra } = thunkApi;

  try {
    const response = await extra.api.post('/api/user', data);

    return response.data;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data?.message ?? 'error');
  }
});

/** Сменить пароль пользователя */
export const recoverPassword = createAsyncThunk<void, IRecoverPassword, ThunkConfig>(
  'user/recoverPassword',
  async (data, thunkApi) => {
    const { rejectWithValue, extra } = thunkApi;

    try {
      const response = await extra.api.post('/api/user/recover', data);

      if (!response.data) {
        throw new Error();
      }

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data?.message ?? 'error');
    }
  }
);
