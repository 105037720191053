import './DriverWorkPage.scss';
import { Page } from '../../layouts/page/Page';
import { Input } from '../../components/input/Input';
import { AppStatus } from '../../components/appStatus/AppStatus';

import fastIcon from '../../assets/img/fast.png';
import { AppButton } from '../../components/appButton/AppButton';
import { Inspection } from '../../components/inspection/Inspection';
import { useEffect, useState } from 'react';
import { WorkShiftStatus } from '../../store/workShifts/types';
import { useWorkShiftStatus } from '../../store/workShifts/useWorkShiftStatus';
import { Loader } from '../../components/loader/Loader';
import { Text } from '../../components/text/Text';
import {
  useCreateWorkShiftMutation,
  useGetCurrentWorkShiftQuery,
  useUpdateCurrentWorkShiftMutation
} from '../../store/workShifts/api';

let intervalId: NodeJS.Timer;

const REFETCH_WORK_SHIFT_SEC = 60;

export const DriverWorkPage = () => {
  const [startCarMileage, setStartCarMileage] = useState('');
  const [endCarMileage, setEndCarMileage] = useState('');

  const {
    data: currentWorkShift,
    isLoading,
    error,
    refetch
  } = useGetCurrentWorkShiftQuery(null, { refetchOnMountOrArgChange: true });

  const [updateCurrentWorkShift] = useUpdateCurrentWorkShiftMutation();
  const [createWorkShift] = useCreateWorkShiftMutation();

  const { isNotWorkStatus, isPendingEndStatus, isPendingStartStatus, isResultStatus, isWorkingStatus } = useWorkShiftStatus(
    currentWorkShift?.status
  );

  // Пулим данные если стоит авто осмотр и статус ожидания
  useEffect(() => {
    if (!currentWorkShift) {
      return;
    }

    if (isPendingStartStatus || isPendingEndStatus) {
      intervalId = setInterval(() => {
        refetch();
      }, REFETCH_WORK_SHIFT_SEC * 1_000);
    } else {
      clearInterval(intervalId);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [currentWorkShift]);

  // Устанавливаем значения инпутов пробега
  useEffect(() => {
    if (!currentWorkShift) {
      return;
    }

    if (currentWorkShift.status !== WorkShiftStatus['not-work']) {
      if (currentWorkShift.startCarMileage) {
        setStartCarMileage(String(currentWorkShift.startCarMileage));
      }
    }

    if (currentWorkShift.endCarMileage) {
      setEndCarMileage(String(currentWorkShift.endCarMileage));
    }
  }, [currentWorkShift]);

  const handleInputMileage = (setValue: React.Dispatch<React.SetStateAction<string>>) => (value: string) => {
    if (/^\d+$/.test(value) || !value) {
      setValue(value);
    }
  };

  const handleClickAppButton = () => {
    if (isLoading || !currentWorkShift) {
      return;
    }

    // Если начинаем смену то нужен начальный пробег. Автоматически переключится на статус pending-start
    if (isNotWorkStatus) {
      if (!+startCarMileage || !/^\d+$/.test(startCarMileage)) {
        setStartCarMileage('Введите пробег');
        return;
      }

      createWorkShift({ startCarMileage: +startCarMileage });
      setStartCarMileage('');
      return;
    }

    // Если ожидаем то просто обновляем информацию о смене
    if (isPendingStartStatus || isPendingEndStatus) {
      refetch();
      return;
    }

    // Если завершаем смену то нужен конечный пробег который больше начального и меняем статус на pending-end
    if (isWorkingStatus) {
      if (!+endCarMileage || +startCarMileage >= +endCarMileage || !/^\d+$/.test(endCarMileage)) {
        setEndCarMileage('Введите корректный пробег');
        return;
      }

      updateCurrentWorkShift({
        id: currentWorkShift.id,
        endCarMileage: +endCarMileage,
        status: WorkShiftStatus['pending-end']
      });
      setEndCarMileage('');
      return;
    }

    // После результирующей страницы меняем статус на выполнено
    if (isResultStatus) {
      updateCurrentWorkShift({ id: currentWorkShift.id, status: WorkShiftStatus.complete });
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  const renderContent = () => {
    if (error || !currentWorkShift) {
      return null;
    }

    return (
      <div className='work-page_wrapper'>
        <AppStatus
          className='work-page_status'
          status={currentWorkShift.status}
        />

        {!isNotWorkStatus && (
          <Inspection
            className='work-page_inspection'
            currentWorkShift={currentWorkShift}
          />
        )}

        <img
          className='work-page_fast-icon'
          src={fastIcon}
          alt='Пробег'
        />
        <form
          className='work-page_form'
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Input
            onClick={() => setStartCarMileage('')}
            value={startCarMileage}
            onChange={handleInputMileage(setStartCarMileage)}
            caption={isNotWorkStatus ? 'Текущий пробег' : 'Пробег начала смены'}
            disabled={!isNotWorkStatus}
          />

          {!isNotWorkStatus && !isPendingStartStatus && (
            <Input
              onClick={() => setEndCarMileage('')}
              value={endCarMileage}
              onChange={handleInputMileage(setEndCarMileage)}
              caption='Текущий пробег'
              disabled={!isWorkingStatus}
            />
          )}
        </form>

        <AppButton
          onClick={handleClickAppButton}
          className='work-page_app-button'
          status={currentWorkShift.status}
        />
      </div>
    );
  };

  return (
    <Page className='work-page'>
      {error && (
        <Text
          color='red'
          size={20}
          uppercase
          align='center'
        >
          Произошла ошибка
        </Text>
      )}

      {renderContent()}
    </Page>
  );
};
