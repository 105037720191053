import { useNavigate } from 'react-router-dom';

import { Page } from '../../layouts/page/Page';
import { Button } from '../../components/button/Button';
import './AdminPage.scss';
import { getRouteRecover, getRouteRegister } from '../../shared/constants/router';
import { useChangeKisArtStatus, useGetAllUsers } from '../../entities/User/api/userApi';
import { Table } from '../../shared/ui/Table';
import { Pagination } from '../../shared/ui/Pagination/Pagination';
import { useState } from 'react';
import { Checkbox } from '../../shared/ui/Checkbox';
import { AdminPageModal } from './AdminPageModal';
import { IUser } from '../../store/user/types';
import { UserRole } from '../../store/user/roles';
import { getRoleName } from '../../shared/lib/getRoleName/getRoleName';

export const AdminPage = () => {
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [modalData, setModalData] = useState<IUser | null>(null);

  const handleClickAddUser = () => {
    navigate(getRouteRegister());
  };

  const handleClickRecoverPassword = () => {
    navigate(getRouteRecover());
  };

  const { data, isLoading, error, refetch, isFetching } = useGetAllUsers(page);

  const [changeKisArtStatusMutation, { isSuccess, isLoading: isChangeLoading }] = useChangeKisArtStatus();

  return (
    <Page className='admin-page'>
      <div className='admin-page_wrapper'>
        <Button
          onClick={handleClickAddUser}
          theme='yellow'
        >
          Добавить пользователя
        </Button>

        <Button
          onClick={handleClickRecoverPassword}
          theme='yellow'
        >
          Восстановить пароль пользователя
        </Button>
      </div>

      {data && (
        <>
          <Table
            rows={data.list}
            columns={[
              {
                name: 'КИС-АРТ',
                getContent: ({ kisArt }) => kisArt
              },
              {
                name: 'Роль',
                getContent: ({ role }) => getRoleName(role)
              },
              {
                name: 'ФИО',
                getContent: ({ name }) => name
              },
              {
                name: 'Телефон',
                getContent: ({ phone }) => phone
              },
              {
                name: 'Email',
                getContent: ({ email }) => email
              },
              {
                name: 'История',
                textWrap: false,
                getContent: (user) =>
                  user.role === UserRole.driver && (
                    <Button
                      style={{ margin: '0 auto' }}
                      onClick={() => setModalData(user)}
                      theme='normal'
                    >
                      Открыть историю
                    </Button>
                  )
              },
              {
                name: 'Авто осмотр',
                getContent: ({ settings, id }) =>
                  settings.isAutoInspection != null && (
                    <Checkbox
                      disabled={isChangeLoading || isFetching || settings.disableAutoInspection}
                      isChecked={settings.isAutoInspection}
                      onChange={(checked) => changeKisArtStatusMutation({ userId: id, newStatus: checked })}
                    />
                  )
              }
            ]}
          />

          <Pagination
            style={{
              marginTop: 15
            }}
            total={data.total}
            defaultPageSize={15}
            showTotal={(total) => `Всего ${total} пользователей`}
            onChange={setPage}
          />
        </>
      )}

      {modalData && (
        <AdminPageModal
          user={modalData}
          onClose={() => setModalData(null)}
        />
      )}
    </Page>
  );
};
