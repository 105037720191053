export interface WorkShiftRequest {
  id: string;
  date: Date;
  kisArt: string;
  name: string;
  phone: string;
  status: WorkShiftStatus;
  userId: number;
  // med
  startMedicalDate?: Date;
  endMedicalDate?: Date;

  // tech
  startCarMileage?: number;
  endCarMileage?: number;
  startTechnicalDate?: Date;
  endTechnicalDate?: Date;
}

export enum WorkShiftStatus {
  /** Нет активной смены */
  'not-work' = 'not-work',
  /** Ожидание начального осмотра */
  'pending-start' = 'pending-start',
  /** В пути */
  'working' = 'working',
  /** Ожидание конечного осмотра */
  'pending-end' = 'pending-end',
  /** Итоги смены */
  'result' = 'result',
  /** Закрытая смена */
  'complete' = 'complete'
}

export interface WorkShift {
  id: string;
  userId?: number;
  startCarMileage?: number;
  endCarMileage?: number;
  startDate?: Date;
  endDate?: Date;
  startMedicalDate?: Date;
  endMedicalDate?: Date;
  startTechnicalDate?: Date;
  endTechnicalDate?: Date;
  status: WorkShiftStatus;
}

export interface WorkShiftsSchema {
  /** Запросы на осмотр (есть у механика и медика) */
  requests: WorkShiftRequest[];
  /** Текущая смена водителя (есть только у водителя) */
  current: WorkShift;
  /** Все смены водителя (история) (есть только у водителя) */
  workShifts: WorkShift[];
  error: string | null;
  isLoading: boolean;
}
