import cn from 'classnames';
import { Text } from '../text/Text';
import appIcon from '../../assets/img/taxi-app.png';
import { WorkShiftStatus } from '../../store/workShifts/types';
import './AppButton.scss';

interface IAppButtonProps {
  className?: string;
  status: WorkShiftStatus;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const AppButton = (props: IAppButtonProps) => {
  const { className, status, onClick } = props;

  const mapStatusText: Record<WorkShiftStatus, [string, string]> = {
    'not-work': ['выйти', 'на линию'],
    'pending-start': ['Проверить', 'статус'],
    working: ['Завершить', 'смену'],
    'pending-end': ['Проверить', 'статус'],
    result: ['До', 'Свидания'],
    complete: ['До', 'Свидания']
  };

  const [topText, bottomText] = mapStatusText[status];

  return (
    <button
      className={cn('app-button', `app-button__${status}`, className)}
      onClick={onClick}
      type='button'
    >
      <Text
        color='white'
        uppercase
      >
        {topText}
      </Text>

      <img
        className='app-button_icon'
        src={appIcon}
        alt=''
      />

      <Text
        color='white'
        uppercase
      >
        {bottomText}
      </Text>
    </button>
  );
};
