import cn from 'classnames';
import { Page } from '../../layouts/page/Page';
import './Loader.scss';

interface ILoaderProps {
  className?: string;
}

export const Loader = (props: ILoaderProps) => {
  const { className } = props;

  return (
    <Page>
      <div className='loader-wrapper'>
        <span className={cn('loader', className)} />
      </div>
    </Page>
  );
};
