import { useState } from 'react';
import { Text } from '../../components/text/Text';
import { Modal } from '../../components/modal/Modal';
import './AdminPage.scss';
import { IUser } from '../../store/user/types';
import { useGetWorkShiftHistoryByUserIdQuery } from '../../entities/User/api/userApi';
import { Table } from '../../shared/ui/Table';
import { Pagination } from '../../shared/ui/Pagination';
import { formatDate } from '../../utils/formatDate';
import { MiniStatus } from '../../components/miniStatus/MiniStatus';

interface IAdminPageModalProps {
  onClose: () => void;
  user: IUser;
}

export const AdminPageModal = (props: IAdminPageModalProps) => {
  const { onClose, user } = props;

  const [page, setPage] = useState(1);
  const { data, isLoading } = useGetWorkShiftHistoryByUserIdQuery(
    { userId: user.id, page },
    { refetchOnMountOrArgChange: true }
  );

  const renderContent = () => {
    if (isLoading) {
      return <Text align='center'>Загрузка...</Text>;
    }

    if (!data?.workShifts.length) {
      return <Text>Водитель еще не работал</Text>;
    }

    return (
      <>
        <div className='admin-page_modal-table'>
          <Table
            rows={data.workShifts}
            columns={[
              {
                name: 'Дата',
                getContent: ({ startDate }) => formatDate(startDate, 'DD.MM.YYYY')
              },
              {
                name: 'ВРЕМЯ СТАРТ',
                getContent: ({ startDate }) => formatDate(startDate, 'HH:mm')
              },
              {
                name: 'ВРЕМЯ ФИНИШ',
                getContent: ({ endDate }) => formatDate(endDate, 'HH:mm')
              },
              {
                name: 'СТАТУС',
                getContent: ({ status }) => <MiniStatus status={status} />
              }
            ]}
          />
        </div>

        <Pagination
          style={{ marginTop: 15 }}
          total={data.total}
          current={page}
          onChange={setPage}
          defaultPageSize={15}
        />
      </>
    );
  };

  return (
    <Modal
      className='admin-page_modal'
      isOpen={true}
      onClose={onClose}
    >
      <Text
        className='admin-page_modal-title'
        size={20}
        uppercase
      >
        История поездок
      </Text>

      <div className='admin-page_modal-name'>
        <Text uppercase>фио</Text>
        <Text>{user.name}</Text>
      </div>

      {renderContent()}
    </Modal>
  );
};
