import { ReactNode, TableHTMLAttributes } from 'react';
import cn from 'classnames';
import cls from './Table.module.scss';
import { Text } from '../../../components/text/Text';

export interface IColumn<T> {
  name: string;
  getContent: (row: T) => ReactNode;
  textWrap?: boolean;
}

interface TableProps<T> extends TableHTMLAttributes<HTMLTableElement> {
  className?: string;
  columns: IColumn<T>[];
  rows: T[];
}

export function Table<T>(props: TableProps<T>) {
  const { className, columns, rows } = props;

  const renderTHead = () => (
    <thead>
      <tr>
        {columns.map(({ name }, i) => (
          <th
            className={cls.cell}
            key={i}
          >
            <Text uppercase>{name}</Text>
          </th>
        ))}
      </tr>
    </thead>
  );

  const renderTBody = () => (
    <tbody>
      {rows.map((row, i) => (
        <tr
          className={cls.row}
          key={i}
        >
          {columns.map(({ getContent, textWrap = true }, i) => (
            <td
              className={cls.cell}
              key={i}
            >
              {textWrap ? <Text weight={500}>{getContent(row)}</Text> : getContent(row)}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  );

  return (
    <table className={cn(className, cls.table)}>
      {renderTHead()}
      {renderTBody()}
    </table>
  );
}
