import { useEffect } from 'react';
import { useAppDispatch } from '../store/useAppDispatch';
import { useIsInited } from '../store/user/selectors';
import { initAuthData } from '../store/user/api';
import { Loader } from '../components/loader/Loader';
import { AppRouter } from './providers/router';

export const App = () => {
  const dispatch = useAppDispatch();

  const inited = useIsInited();

  useEffect(() => {
    if (!inited) {
      dispatch(initAuthData());
    }
  }, [inited]);

  if (!inited) {
    return <Loader />;
  }

  return <AppRouter />;
};
