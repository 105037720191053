import { useMemo } from 'react';
import { Navigate, useLocation } from 'react-router';
import { useIsAuth, useRole } from '../../../../store/user/selectors';
import { UserRole } from '../../../../store/user/roles';
import { getRouteLogin, getRouteMain } from '../../../../shared/constants/router';

interface RequireAuthProps {
  children: JSX.Element;
  roles?: UserRole[];
}

export function RequireAuth({ children, roles }: RequireAuthProps) {
  const isAuth = useIsAuth();

  const location = useLocation();
  const userRole = useRole();

  const hasRequiredRoles = useMemo(() => {
    if (!roles) {
      return true;
    }

    return roles.some((requiredRole) => requiredRole === userRole);
  }, [roles, userRole]);

  if (!isAuth) {
    return (
      <Navigate
        to={getRouteLogin()}
        state={{ from: location }}
        replace
      />
    );
  }

  if (!hasRequiredRoles) {
    return (
      <Navigate
        to={getRouteMain()}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}

export function IsAuth({ children }: RequireAuthProps) {
  const isAuth = useIsAuth();

  const location = useLocation();

  if (isAuth) {
    return (
      <Navigate
        to={getRouteMain()}
        state={{ from: location }}
        replace
      />
    );
  }

  return children;
}
