import { FC, ReactNode } from 'react';
import cn from 'classnames';

import './Modal.scss';
import { useModal } from './useModal';
import { createPortal } from 'react-dom';
import { Button } from '../button/Button';
import userIcon from '../../assets/img/close.png';

interface ModalProps {
  className?: string;
  children: ReactNode;
  /**
   * Флаг открытия
   */
  isOpen?: boolean;
  /**
   * Вызывается при закрытии
   */
  onClose?: () => void;
  /**
   * Удаляет из DOM при закрытии
   */
  lazy?: boolean;
}

export const Modal: FC<ModalProps> = ({ className, children, isOpen, onClose }) => {
  const { close, isClosing } = useModal({ onClose, isOpen });

  return createPortal(
    <div
      className={cn('modal', className, {
        opened: isOpen,
        isClosing: isClosing
      })}
    >
      <div
        className='modal_overlay'
        onClick={close}
      />

      <div className='modal_content'>
        <Button
          onClick={close}
          className='modal_close-button'
          icon={userIcon}
        />

        {children}
      </div>
    </div>,
    document.body
  );
};
